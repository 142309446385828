import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/modules')
const postAxios = data => axiosIns.post('/modules', data)
const oneAxios = id => axiosIns.get(`/modules/${id}`)
const searchAxios = id => axiosIns.get(`/modules/search/${id}`)
const putAxios = (id, data) => axiosIns.put(`/modules/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/modules/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  searchAxios,
  putAxios,
  deleteAxios,
}
